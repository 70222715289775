import React, { useEffect, useState, useContext, useCallback } from "react";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";
import { Button, Row, Col, Alert, Form, Container } from "react-bootstrap";

import axios from "axios";

import AADService from "../../auth/authFunctions";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import { forwardRef } from "react";
import MaterialTable from "material-table";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TextField from "@mui/material/TextField";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import Loader from "react-loader-spinner";

import ModalInfo from "./Modales/ModalInfo";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

function AlertDismissibleExample({ alerta }) {
  switch (alerta.id) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;

    case 2:
      return <Alert variant="success">Guardado con éxito!</Alert>;

    case 3:
      return <Alert variant="danger">{alerta.data}</Alert>;

    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;

    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;

    case 7:
      return (
        <Alert variant="warning">
          Corrige los siguientes errores:
          <br></br>• Debe completar los campos obligatorios
        </Alert>
      );
    default:
      return <p></p>;
  }
}

export default function ImpactoAnalista() {
  const serviceAAD = new AADService();
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();

  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [buscando, setBuscando] = React.useState(null);

  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  //------------------------------------------------------------------

  const [dataGrid, setDataGrid] = useState([]);

  const [dataUser, setDataUser] = useState([]);

  const [deshabilitado, setDeshabilitado] = useState(false);

  const [showModalInfo, setShowModalInfo] = useState(false);

  const defaultValues = {
    emailUsuario: null,
    nombreUsuario: null,
    companias: null,
    fechaCorte: null,
    reputacional: null,
    legal: null,
    operacional: null,
    contagio: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const ActualizarMatriz = (index, property, value) => {
    let temp = dataGrid;
    temp[index][property] = parseFloat(value);

    setDataGrid(temp);
  };

  const ValidacionFormulario = () => {
    const inputs = document.querySelectorAll("input");

    let esValido = true;

    inputs.forEach((input) => {
      input.reportValidity();
      if (input.value === null || input.value === "" || isNaN(input.value)) {
        input.value = 0;
      } else if (input.checkValidity() === false) {
        esValido = false;
      }
    });

    return esValido;
  };

  const PreventGridNull = () => {
    // Funcion para transformar los valores de los inputs a 0 si estan vacios, nulos o NaN
    dataGrid.map((item) => {
      item.riesgo_reputacional =
        item.riesgo_reputacional === "" ||
        isNaN(item.riesgo_reputacional) ||
        item.riesgo_reputacional === null
          ? 0
          : item.riesgo_reputacional;
      item.riesgo_legal =
        item.riesgo_legal === "" ||
        isNaN(item.riesgo_legal) ||
        item.riesgo_legal === null
          ? 0
          : item.riesgo_legal;
      item.riesgo_operacional =
        item.riesgo_operacional === "" ||
        isNaN(item.riesgo_operacional) ||
        item.riesgo_operacional === null
          ? 0
          : item.riesgo_operacional;
      item.riesgo_contagio =
        item.riesgo_contagio === "" ||
        isNaN(item.riesgo_contagio) ||
        item.riesgo_contagio === null
          ? 0
          : item.riesgo_contagio;
    });
  };

  const Guardar = () => {
    PreventGridNull();

    const esValido = ValidacionFormulario();



    if (esValido) {

      const respuestaUsuario = window.confirm(
        "¿Está seguro de enviar el formulario?, al aceptar ya no podrá modificar su respuesta"
      ); 
  

      if (respuestaUsuario) {

      let url =
      process.env.REACT_APP_API_URL +
      "/valoracion_impacto/" +
        dataUser.fecha_corte +
        "/" +
        dataUser.email_usuario +
        "/";

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + serviceAAD.getToken(),
        },
      };

      switch (dataUser.estado) {
        case "Enviado":
          axios.post(url, dataGrid, config).then((response) => {
            if (response.status >= 200 && response.status < 300) {

              axios
                .put(
                  process.env.REACT_APP_API_URL +
                    "/valoracion_impacto_estado/" +
                    dataUser.fecha_corte +
                    "/" +
                    dataUser.email_usuario +
                    "/",
                  { estado: "Finalizado" },
                  config
                )
                .then((response2) => {
                  if (response2.status >= 200 && response2.status < 300) {
                    alert("Guardado con éxito");
                    history.push("/Impacto");
                  } else if (response2.status >= 500) {
                  } else if (
                    response2.status >= 400 &&
                    response2.status < 500
                  ) {
                  }
                });
            }
          });
          break;
        case "Iniciado":
          axios.put(url, dataGrid, config).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");

              axios
                .put(
                  process.env.REACT_APP_API_URL +
                    "/valoracion_impacto_estado/" +
                    dataUser.fecha_corte +
                    "/" +
                    dataUser.email_usuario +
                    "/",
                  { estado: "Finalizado" },
                  config
                )
                .then((response2) => {
                  if (response2.status >= 200 && response2.status < 300) {
                    alert("Guardado con éxito");
                    history.push("/Impacto");
                  } else if (response2.status >= 500) {
                  } else if (
                    response2.status >= 400 &&
                    response2.status < 500
                  ) {
                  }
                });
            }
          });
          break;
        default:
          break;
      }
    }
    }
  };

  const onSubmit = () => {

    PreventGridNull();

    const esValido = ValidacionFormulario();

    if (esValido) {
      const url =
        process.env.REACT_APP_API_URL +
        "/valoracion_impacto/" +
        dataUser.fecha_corte +
        "/" +
        dataUser.email_usuario +
        "/";

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + serviceAAD.getToken(),
        },
      };

      switch (dataUser.estado) {
        case "Enviado":
          axios.post(url, dataGrid, config).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");
              history.push("/Impacto");
              // history.push({
              //   pathname: "/EditarCausaCumplimiento",
              //   state: { idCausa: id },
              // });
            }
          });
          break;
        case "Iniciado":
          axios.put(url, dataGrid, config).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");
              history.push("/Impacto");
              // history.push({
              //   pathname: "/EditarCausaCumplimiento",
              //   state: { idCausa: id },
              // });
            }
          });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (typeof location.state != "undefined") {
      setDataUser(location.state);

      let url = process.env.REACT_APP_API_URL + "/valoracion_impacto";

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + serviceAAD.getToken(),
        },
      };
      try {
        axios
          .get(
            url +
              "/" +
              location.state.fecha_corte +
              "/" +
              location.state.email_usuario +
              "/",
            config
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              let temp = response.data;


              temp.forEach((item) => {
                item.fecha_corte = location.state.fecha_corte;
                item.compania = location.state.compania;
                item.idusuario = location.state.idusuario;
                item.nombre_usuario = location.state.nombre_usuario;
                item.email_usuario = location.state.email_usuario;
              });

              if (
                location.state.estado === "Finalizado" ||
                location.state.ver === true
              ) {
                setDeshabilitado(true);
                temp.map((item) => {
                  item.aplica_contagio = 0;
                  item.aplica_legal = 0;
                  item.aplica_reputacional = 0;
                  item.aplica_operacional = 0;
                });
              }

              setDataGrid(temp);

              setEstadoPost({ id: 2, response: response });
            } else if (response.status >= 500) {
              setEstadoPost({ id: 5, response: response });
            } else if (response.status >= 400 && response.status < 500) {
              setEstadoPost({ id: 4, response: response });
            }
          });
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Ups, ocurrió un error, trata de recargar la página");
    }
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <ModalInfo
          show={showModalInfo}
          onHide={() => setShowModalInfo(false)}
        />
        {/* <AlertDismissibleExample alerta={estadoPost} /> */}
        <Container fluid>
          <Row className="mb-3 mt-3">
            <Col sm={10} xs={12}>
              <h1 className="titulo">Valoración Impacto LAFT</h1>
            </Col>

            <Col sm={2} xs={12}>
              <Link to="/Impacto">
                <Button className="botonNegativo">Regresar</Button>
              </Link>
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col sm={2} xs={12}>
              <label className="form-label label">Usuario</label>
            </Col>
            <Col sm={4} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={dataUser.email_usuario}
              />
            </Col>

            <Col sm={2} xs={12}>
              <label className="form-label label">Nombre de Usuario</label>
            </Col>
            <Col sm={4} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                value={dataUser.nombre_usuario}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Compañías</label>
            </Col>
            <Col sm={4} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={dataUser.compania}
              />
            </Col>

            <Col sm={2} xs={12}>
              <label className="forn-label label">Fecha Corte</label>
            </Col>
            <Col sm={4} xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={dataUser.fecha_corte}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={10} xs={12}>
              <label className="forn-label label">
                *Por favor, ingrese una calificación de 0 a 3 para cada uno de
                los riesgos asociados a cada subcategoría de causa, donde 0
                corresponda a que no existe afectación alguna y 3 corresponde al
                máximo impacto o afectación posible
              </label>
            </Col>
            <Col sm={2} xs={12}>
              <Button onClick={() => setShowModalInfo(true)}>
                <InfoOutlinedIcon fontSize="small" />
              </Button>
            </Col>
          </Row>

          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell>ID</StyledTableCell>

                    <StyledTableCell>Categoría</StyledTableCell>

                    <StyledTableCell align="left">Subcategoría</StyledTableCell>

                    <StyledTableCell align="left">
                      Riesgo Reputacional
                    </StyledTableCell>

                    <StyledTableCell align="left">Riesgo Legal</StyledTableCell>

                    <StyledTableCell align="left">
                      Riesgo Operacional
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      Riesgo de Contagio
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                  {dataGrid
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          key={row.idcategoria}
                          hover
                          onClick={(event) =>
                            handleClick(event, row.idcategoria)
                          }
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            {row.idcategoria ? row.idcategoria : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.nombre_categoria !== null
                              ? row.nombre_categoria
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.nombre_subcategoria !== null
                              ? row.nombre_subcategoria
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <input
                              id="reputacional"
                              type="number"
                              min="0"
                              max="3"
                              step="0.01"
                              disabled={
                                row.aplica_reputacional === 0 ? true : false
                              }
                              className="form-control text-center texto"
                              defaultValue={row.riesgo_reputacional}
                              onChange={(e) => {
                                ActualizarMatriz(
                                  index,
                                  "riesgo_reputacional",
                                  e.target.value
                                );
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <input
                              id="legal"
                              type="number"
                              min="0"
                              max="3"
                              step="0.01"
                              disabled={row.aplica_legal === 0 ? true : false}
                              className="form-control text-center texto"
                              defaultValue={row.riesgo_legal}
                              onChange={(e) => {
                                ActualizarMatriz(
                                  index,
                                  "riesgo_legal",
                                  e.target.value
                                );
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <input
                              id="operacional"
                              type="number"
                              min="0"
                              max="3"
                              step="0.01"
                              disabled={
                                row.aplica_operacional === 0 ? true : false
                              }
                              className="form-control text-center texto"
                              defaultValue={row.riesgo_operacional}
                              onChange={(e) => {
                                ActualizarMatriz(
                                  index,
                                  "riesgo_operacional",
                                  e.target.value
                                );
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <input
                              id="contagio"
                              type="number"
                              min="0"
                              max="3"
                              step="0.01"
                              disabled={
                                row.aplica_contagio === 0 ? true : false
                              }
                              className="form-control text-center texto"
                              defaultValue={row.riesgo_contagio}
                              onChange={(e) => {
                                ActualizarMatriz(
                                  index,
                                  "riesgo_contagio",
                                  e.target.value
                                );
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
          <br />
          {/* {Aparición de botones solo cuando "deshabilitado" es false } */}
          {deshabilitado === false ? ( 
          <Row className="mb-4">
            <Col sm={8} xs={12}></Col>
            <Col sm={2} xs={12}>
              <Button
                type="submit"
                value="submit"
                className="botonNegativo"
                onClick={onSubmit}
              >
                Guardar y Salir
              </Button>
            </Col>

            <Col sm={2} xs={12}>
              <Button
                type="submit"
                value="submit"
                class="btn btn-primary"
                onClick={Guardar}
              >
                Guardar y Enviar
              </Button>
            </Col>
          </Row>
          ) : null}
        </Container>
      </FormProvider>
    </>
  );
}
