import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";
import { Button, Row, Col, Form, Container } from "react-bootstrap";

import AADService from "../../auth/authFunctions";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";

import Loader from "react-loader-spinner";
import axios from "axios";

import { UsuarioContext } from "../../Context/UsuarioContext";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function Expertos() {
  const serviceAAD = new AADService();
  const classes = useStyles();
  let history = useHistory();

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [buscando, setBuscando] = React.useState(null);

  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  const { dataUsuario } = React.useContext(UsuarioContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Ver = () => {
    let data = dataBusqueda.filter((item) => item.idvaloracion_probabilidad === selected[0]);

    data.map((item) => (item.ver = true));

    console.log(data);

    if (data.length === 0) {
      alert('Seleccione primero una valoración');
      return;
    }
    
    setTimeout(
      history.push({
        pathname: "/ValoracionExpertos",
        state: data[0],
      }),
      3000
    );
  };

  const Diligenciar = () => {
    let data = dataBusqueda.filter((item) => item.idvaloracion_probabilidad === selected[0]);

    if (data.length === 0) {
      alert('Seleccione primero una valoración');
      return;
    }

    setTimeout(
      history.push({
        pathname: "/ValoracionExpertos",
        state: data[0],
      }),
      3000
    );
  };

  useEffect(() => {
    let url = process.env.REACT_APP_API_URL + "/valoracion_expertos/";
    let email = dataUsuario.email;
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };
    try {
      axios.get(url + email + "//" , config).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setDataBusqueda(response.data);

          console.log(response.data);

          // setEstadoPost({ id: 2, response: response });
        } else if (response.status >= 500) {
          //setEstadoPost({ id: 5, response: response });
        } else if (response.status >= 400 && response.status < 500) {
          //setEstadoPost({ id: 4, response: response });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="mb-3 mt-3">
          <Col sm={8} xs={12}>
            <h1 className="titulo">Valoración Probabilidad LAFT</h1>
          </Col>

          <Col sm={2} xs={12}>
            <Button className="botonNegativo" onClick={Ver}>
              Ver
            </Button>
          </Col>

          <Col sm={2} xs={12}>
            <Button className="botonPositivo" onClick={Diligenciar}>
              Diligenciar
            </Button>
          </Col>
        </Row>

        {loadingData ? (
          <Row className="mb-3 mt-5">
            <Col>
              <Loader
                type="Oval"
                color="#FFBF00"
                style={{ textAlign: "center", position: "static" }}
              />
            </Col>
          </Row>
        ) : (
          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    
                    <StyledTableCell>Id Probabilidad Expertos</StyledTableCell>

                    <StyledTableCell>IDs Causas Asociadas</StyledTableCell>

                    <StyledTableCell>Usuario</StyledTableCell>

                    <StyledTableCell>Fecha Corte</StyledTableCell>

                    <StyledTableCell>Estado</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {dataBusqueda
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.idvaloracion_probabilidad);
                      return (
                        <StyledTableRow
                          key={row.idvaloracion_probabilidad}
                          hover
                          onClick={(event) => 
                            handleClick(event, row.idvaloracion_probabilidad)
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.idvaloracion_probabilidad !== null ? row.idvaloracion_probabilidad : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.ids_causa !== null ? row.ids_causa : null}
                          </StyledTableCell>
                          
                          <StyledTableCell component="th" scope="row">
                            {row.email_usuario ? row.email_usuario : null}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {row.fecha_corte ? row.fecha_corte : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.estado === "Enviado"
                              ? "Pendiente"
                              : row.estado}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dataBusqueda.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
        )}
      </Container>
    </>
  );
}
